import { Box, Typography } from '@material-ui/core'
import React from 'react'
import { ZoomRoles } from 'src/types'
import { getRoleAndDisplayNameZoomCall } from 'src/utils/getRoleAndDisplayNameZoomCall'

export const MessageItem = ({ message }) => {
    const { from } = message
    const { displayName, role } = getRoleAndDisplayNameZoomCall(from)
    // console.log('message', message.sender.name)
    const isFromOperator = role === ZoomRoles.OPERATOR
    const corners = isFromOperator ? '10px 0px 10px 10px' : '0px 10px 10px 10px'

    return (
        <Box
            style={{
                maxWidth: '80%',
                color: isFromOperator ? '#000' : '#fff',
                backgroundColor: isFromOperator ? '#fff' : '#2196F3',
                alignSelf: 'flex-end',
                borderRadius: corners,
                margin: 10,
                padding: 5,
                boxShadow:
                    '0px 3px 5px rgba(0, 0, 0, 0.2), 0px 5px 8px rgba(0, 0, 0, 0.14)',
                alignItems: 'center',
                marginRight: isFromOperator ? 10 : 'auto'
            }}
        >
            {!isFromOperator && (
                <Typography variant="body2">{displayName}</Typography>
            )}
            <Typography variant="body2">{message.data}</Typography>
        </Box>
    )
}
