import React, { useEffect, useState, useContext, useCallback } from 'react'
import ZoomVideo from '@zoom/videosdk'
import { Box } from '@material-ui/core'
import ZoomContext from '../context/zoom-context'
import { startVideo, startAudio } from './helpers'
import { useCallContext } from '../reducers/Call/CallProvider'
import AudioOnIcon from '../assets/sound-off-icon.svg'
import AudioOffIcon from '../assets/volumeIconOff.svg'
import DeclineBtn from '../assets/declineButton.svg'
import { getRoleAndDisplayNameZoomCall } from 'src/utils/getRoleAndDisplayNameZoomCall'
import { ZoomActions, ZoomRoles } from 'src/types'

let stream
let audioEncode
let audioDecode

// mock
//             {
//     "avatar": "https://images.zoom.us/p/v2/3909a0262040db24d19b410e497515562b78bd4e00ecd694f1500a9390203a69/b5df5bf5-792d-4b4e-9c51-5a45efa22424-3728?type=large",
//     "isAllowIndividualRecording": false,
//     "isManager": true,
//     "displayName": "amsaan-pro.company",
//     "userId": 16787456,
//     "isHost": false,
//     "isPhoneUser": false,
//     "userGuid": "E1C057DE-E646-0596-CC1D-1CDF0C0F2907",
//     "isHandUp": false,
//     "isActiveUser": false
// }

export const ZoomGroupCall = ({
    topic,
    token,
    userName = 'Operator',
    password = '12345678',
    isForceDisconnect = false
}) => {
    const zmClient = useContext(ZoomContext)
    const [isUserMute, setIsUserMute] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const {
        streamAvailable,
        setStreamAvailable,
        isOperatorMuted,
        setIsOperatorMuted,
        isCameraOff,
        setIsCameraOff,
        endCall,
        forceDisconnect,
        addUserInSession,
        removeUserInSession,
        setHandUpCount,
        setUsersInSession,
        usersInSession
    } = useCallContext()
    // const commandChannelRef = React.useRef(zmClient.getCommandClient())

    const handleForceDisconnect = useCallback(() => {
        // return;

        forceDisconnect(topic)
        zmClient.leave()
        setIsLoading(false)
        endCall()
    }, [forceDisconnect, topic, zmClient, endCall])

    const init = useCallback(async () => {
        setIsOperatorMuted(false)
        setIsCameraOff(false)

        await zmClient.init('en-US', 'CDN', {
            enforceMultipleVideos: true,
            patchJsMedia: true
        })

        // console.log('topic, token', { topic, token })
        try {
            //setStreamAvailable(false)
            setIsLoading(true)
            await zmClient
                .join(topic, token, `operator.${userName}`, password)
                .then(async (d) => {
                    stream = zmClient.getMediaStream()
                    // zmClient.getAllUser().forEach((user) => {
                    //     console.log('user in join', user)
                    //     if (user.bVideoOn) {
                    //         stream
                    //             .attachVideo(user.userId, 3)
                    //             .then((userVideo) => {
                    //                 console.log('userVideo', userVideo)
                    //                 document
                    //                     .querySelector('video-player-container')
                    //                     .appendChild(userVideo)
                    //             })
                    //     }
                    // })
                    await startVideo(stream, zmClient)
                    await startAudio(stream, audioEncode, audioDecode)
                    // console.log('init then', d)
                    // stream = zmClient.getMediaStream()
                    // console.log('stream init', stream)
                    // stream.startVideo().then(() => {
                    //     stream
                    //         .attachVideo(
                    //             zmClient.getCurrentUserInfo().userId,

                    //         )
                    //         .then((userVideo) => {
                    //             console.log('userVideo', userVideo)
                    //             document
                    //                 .querySelector('video-player-container')
                    //                 .appendChild(userVideo)
                    //         })
                    // })
                    // await startVideo(stream, zmClient)
                    // await startAudio(stream, audioEncode, audioDecode)
                })
                .catch((e) => {
                    console.log('Error in join session ', e)
                    // window.location.reload()
                })
            // stream = zmClient.getMediaStream()
            // console.log('stream init', stream)
            // await startVideo(stream, zmClient)
            // await startAudio(stream, audioEncode, audioDecode)
        } catch (error) {
            console.log('Error in init', error)
        }
    }, [
        password,
        setIsOperatorMuted,
        token,
        topic,
        userName,
        zmClient,
        setIsCameraOff
    ])

    useEffect(() => {
        if (!topic || !token) {
            return
        }

        init()

        return () => {
            ZoomVideo.destroyClient()
        }
    }, [
        password,
        setIsOperatorMuted,
        setStreamAvailable,
        token,
        topic,
        userName,
        zmClient,
        init
    ])

    useEffect(() => {
        const onUserAdded = async ([payload]) => {
            // const [role, ...rest] = payload.displayName.split('.')
            // const displayName = rest.join('.')
            const { displayName, role } = getRoleAndDisplayNameZoomCall(
                payload.displayName
            )
            const processedData = {
                ...payload,
                isHandUp: false,
                isActiveUser: false,
                role,
                displayName
            }
            // console.log('processedData', processedData)
            addUserInSession(processedData)

            setStreamAvailable(true)
            setIsLoading(false)

            // console.log(
            //     processedData.userId + ' joined the session ',
            //     processedData
            // )
        }

        const onUserRemoved = ([payload]) => {
            if (!Boolean(payload)) {
                return
            }
            //console.log('USERS---> ', payload.length)
            // console.log(payload?.userId + ' left the session')
            removeUserInSession(payload.userId)
            // console.log('onUserRemoved usersInSession', usersInSession)
            // if (payload?.userId) {
            //     endCall()
            //     zmClient.leave(true)
            // }
        }
        const onUserUpdated = (payload) => {
            //console.log(payload[0].userId + ' properties were updated')
            //console.log('properties: ', payload[0])
        }

        const onVideoStateChange = async (payload) => {
            console.log('Video State Change Payload:', payload)
            // const isAmsaanProUser = usersInSession.some(el => el.userId === payload.userId);
            console.log('usersInSession', usersInSession)
            const amsaanProUser = usersInSession.find(
                (el) => el.role === ZoomRoles.AMSAAN_PRO
            )
            console.log('amsaanProUser', amsaanProUser)
            const isAmsaanProUser = amsaanProUser && amsaanProUser.userId === payload.userId
            console.log('isAmsaanProUser', isAmsaanProUser)

            if (isAmsaanProUser && payload.action === 'Start') {
                stream.attachVideo(payload.userId, 3).then((userVideo) => {
                    console.log('userVideo', userVideo)
                    setUsersInSession((prev) => {
                        // console.log('prev', prev)
                        if (!Boolean(prev.length)) {
                            return prev
                        }
                        return prev.map((el) =>
                            el.userId === amsaanProUser.userId
                                ? { ...el, isActiveUser: true }
                                : el
                        )
                    })
                    document
                        .querySelector('video-player-container')
                        .appendChild(userVideo)
                })
                setStreamAvailable(true)
                setIsLoading(false)
            }
            // if (!stream) {
            //     console.warn('Stream is not defined.')
            //     // Attempt to reinitialize the stream
            //     try {
            //         stream = zmClient.getMediaStream()
            //         console.log('Reinitialized stream:', stream)
            //     } catch (error) {
            //         console.error('Error reinitializing stream:', error)
            //         return
            //     }
            // }

            // if (payload.action === 'Start') {
            //     try {
            //         await stream.renderVideo(
            //             document.querySelector('#participants-canvas'),
            //             payload.userId,
            //             1920,
            //             1080,
            //             0,
            //             0,
            //             3
            //         )

            // setStreamAvailable(true)
            // setIsLoading(false)
            //     } catch (err) {
            //         console.error('Error in renderVideo:', err)
            //     }
            // }
        }

        // const onVideoStateChange = async (payload) => {
        //     if (payload.action === 'Start') {
        //         // console.log('stream', stream)
        //         // if (!stream) {
        //         //     stream = zmClient.getMediaStream()
        //         // }
        //         await stream
        //             .renderVideo(
        //                 document.querySelector('#participants-canvas'),
        //                 payload.userId,
        //                 1920,
        //                 1080,
        //                 0,
        //                 0,
        //                 3
        //             )
        //             .then(() => {
        //                 setStreamAvailable(true)
        //                 setIsLoading(false)
        //             })
        //             .catch((err) => {
        //                 console.log('VideoStateChange ', err)
        //             })
        //     }
        // }

        const onMediaSDKChange = (payload) => {
            if (payload.type === 'audio' && payload.result === 'success') {
                if (payload.action === 'encode') {
                    // encode for sending audio stream (speak)
                    audioEncode = true
                    console.log(' audioEncode ', audioEncode)
                } else if (payload.action === 'decode') {
                    // decode for receiving audio stream (hear)
                    audioDecode = true
                    console.log(' audioDecode ', audioDecode)
                }
            }
        }

        const onAutoPlayAudioFailed = () => {
            console.log('auto play failed, waiting for a user interaction')
        }

        const onCommandChannelMessage = (e) => {
            console.log('e', e)
            //             {
            //     "senderId": "33555456",
            //     "senderName": "amsaan.380635957719",
            //     "senderGuid": "0D53F3A6-01BE-71EA-56FD-7506CED18DCF",
            //     "text": "hand_down",
            //     "timestamp": 1725275294714,
            //     "msgid": "5A961F7E-32E2-4D76-95ED-592049B72B01"
            // }
            switch (e.text) {
                case ZoomActions.HAND_UP:
                    setUsersInSession((prev) => {
                        // const
                        // console.log('prev', prev)
                        return prev.map((el) => {
                            if (
                                String(el.userId) === String(e.senderId) &&
                                !el.isHandUp
                            ) {
                                setHandUpCount((prev) => prev + 1)
                            }

                            return String(el.userId) === String(e.senderId)
                                ? { ...el, isHandUp: true }
                                : el
                        })
                    })
                    break
                case ZoomActions.HAND_DOWN:
                    setUsersInSession((prev) => {
                        // const
                        return prev.map((el) => {
                            if (
                                String(el.userId) === String(e.senderId) &&
                                el.isHandUp
                            ) {
                                setHandUpCount((prev) => prev - 1)
                            }
                            return String(el.userId) === String(e.senderId)
                                ? { ...el, isHandUp: false }
                                : el
                        })
                    })
                    break
                //                 case ZoomActions.ATTACH_USER:
                // commandChannelRef.current.send()
                // break
                default:
                    break
            }

            // setHandUpCount, setUsersInSession
            //             {
            //     "senderId": "16787456",
            //     "senderName": "test",
            //     "senderGuid": "787FF302-2E3C-8419-D477-C9DC690EDE51",
            //     "text": "raise_hand",
            //     "timestamp": 1723472114702,
            //     "msgid": "6CEA2DE1-C44D-4BF9-B15B-F84EA019CA08"
            // }
            console.log('command-channel-message', e)
        }
        const onActiveSpeaker = (payload) => {
            console.log('Active speaker', payload)
        }
        const onVideoActiveChange = async (payload) => {
            console.log('video-active-change', payload)
            try {
                if (payload.state === 'Active') {
                    // await stream.renderVideo(canvas, userId, 1280, 720, 0, 0, 3)
                } else {
                    // await stream.stopRenderVideo(canvas, userId)
                }
            } catch (error) {
                console.log(error)
            }
        }
        zmClient.on('auto-play-audio-failed', onAutoPlayAudioFailed)
        zmClient.on('media-sdk-change', onMediaSDKChange)
        zmClient.on('peer-video-state-change', onVideoStateChange)
        zmClient.on('user-added', onUserAdded)
        zmClient.on('user-removed', onUserRemoved)
        zmClient.on('user-updated', onUserUpdated)
        zmClient.on('command-channel-message', onCommandChannelMessage)
        // zmClient.on('')
        // zmClient.on('active-speaker', onActiveSpeaker)
        // zmClient.on('video-active-change', onVideoActiveChange)

        return () => {
            zmClient.off('user-added', onUserAdded)
            zmClient.off('user-removed', onUserRemoved)
            zmClient.off('user-updated', onUserUpdated)
            zmClient.off('peer-video-state-change', onVideoStateChange)
            zmClient.off('media-sdk-change', onMediaSDKChange)
            zmClient.off('auto-play-audio-failed', onAutoPlayAudioFailed)
            zmClient.off('command-channel-message', onCommandChannelMessage)
            // zmClient.off('active-speaker', onActiveSpeaker)
            // zmClient.off('video-active-change', onVideoActiveChange)
        }
    }, [
        zmClient,
        endCall,
        setIsOperatorMuted,
        setIsCameraOff,
        setStreamAvailable,
        usersInSession
    ])

    useEffect(() => {
        if (!zmClient.getSessionInfo().isInMeeting) {
            return
        }

        const checkIsOperatorMuted = async () => {
            try {
                if (isOperatorMuted) {
                    await stream.muteAudio()
                } else {
                    await stream.unmuteAudio()
                }
            } catch (error) {
                console.log('checkIsOperatorMuted', error)
            }
        }

        checkIsOperatorMuted()
    }, [isOperatorMuted, zmClient])

    useEffect(() => {
        if (!zmClient.getSessionInfo().isInMeeting) {
            return
        }
        console.log('stream', stream)
        const checkIsCameraOff = async () => {
            try {
                if (isCameraOff) {
                    await stream.stopVideo()
                } else {
                    await startVideo(stream, zmClient)
                }
            } catch (error) {
                console.log('checkIsCameraOff', error)
            }
        }

        checkIsCameraOff()
    }, [zmClient, isCameraOff])

    //force disconnect
    useEffect(() => {
        let timerId

        if (!isLoading) {
            clearTimeout(timerId)
            return
        }

        timerId = setTimeout(() => {
            handleForceDisconnect()
        }, 20000)

        return () => {
            clearTimeout(timerId)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading])

    useEffect(() => {
        if (isForceDisconnect) {
            handleForceDisconnect()
        }
    }, [isForceDisconnect, handleForceDisconnect])

    const changeAudioLocally = async () => {
        try {
            const participants = zmClient.getAllUser()
            const operator = zmClient.getCurrentUserInfo()
            const [amsaanUser] = participants.filter(
                (user) => user.userId !== operator.userId
            )
            if (isUserMute) {
                await stream.unmuteUserAudioLocally(amsaanUser.userId)
                setIsUserMute(false)
                console.log('changeAudioLocally unmute')
            } else {
                await stream.muteUserAudioLocally(amsaanUser.userId)
                setIsUserMute(true)
                console.log('changeAudioLocally mute')
            }
        } catch (error) {
            console.log('Error in changeAudioLocally ', error)
        }
    }

    const onDeclineButtonClick = () => {
        try {
            endCall()
            zmClient.leave()
        } catch (error) {
            console.log('error in onDeclineButtonClick ', error)
        }
    }

    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            overflow="hidden"
        >
            <video
                style={{
                    display: 'none'
                }}
                id="self-view-video"
            ></video>
            <video-player-container
                style={{
                    width: '200%',
                    height: 'auto',
                    display: 'block',
                    maxHeight: 'calc(100vh - 40px)'
                }}
            ></video-player-container>
            {/* <video></video> */}
            {/* <canvas
                style={{
                    display: 'none'
                }}
                id="self-view-canvas"
            ></canvas>
            <div style={styles.canvasWrapper}>
                <canvas
                    style={styles.participantsCanvas}
                    id="participants-canvas"
                    width="1920"
                    height="1080"
                ></canvas>
            </div> */}
            {streamAvailable && (
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    style={styles.streamBox}
                >
                    {' '}
                    <button onClick={changeAudioLocally} style={styles.button}>
                        <img
                            src={isUserMute ? AudioOnIcon : AudioOffIcon}
                            alt="Audio"
                        />
                    </button>
                    <button
                        onClick={onDeclineButtonClick}
                        style={styles.button}
                    >
                        <img src={DeclineBtn} alt="Decline" />
                    </button>
                </Box>
            )}
        </Box>
    )
}

const styles = {
    button: {
        backgroundColor: 'transparent',
        border: 'none',
        cursor: 'pointer',
        margin: '0 10px'
    },
    streamBox: {
        position: 'absolute',
        bottom: '50px',
        left: '50%',
        transform: 'translate(-50%, 0)'
    },
    participantsCanvas: {
        width: '200%',
        height: 'auto',
        display: 'block',
        maxHeight: 'calc(100vh - 40px)'
    },
    canvasWrapper: {
        display: 'flex',
        justifyContent: 'center',
        overflow: 'hidden'
    }
}
